export const environment = {
  production: false,
  previewAvailable: true,
  donation_url: 'https://donate.staging.qr-pay.net',
  appVersion: 'v1',
  host_domain: 'https://api-staging.qr-pay.net',
  tenant_domain: 'api-staging.qr-pay.net',
  media_server: 'https://media.staging.qr-pay.net/',
  postcodeLookup: 'api.postcodes.io/postcodes/',
  httpProtocol: 'https://',
  recaptcha: {
    siteKey: '6Ld5SvciAAAAACstbwOGadwIBFxsv6DTjeYFhDkU',
  },
  tagManagerID: 'G-KKG161DEGF',
  googleMapsKey: 'AIzaSyC3gM73fTSgZRmxeHVY1mB7QlX8PbJaMvg',
  stripe_public_key: 'pk_test_51Jtz1iClZcYCUJ5324uIgYqpiyBE3A62QStlsLXpFiPzURk7550WTRPmAHTyBt29bQhFC8ZeEuFw5rWh31wy35RQ00vUQlqTRV',
  auth0: {
    domain: 'qrpay-staging.uk.auth0.com',
    clientId: '9Qn5IFQgQ27zDczyYETbSJWJd5RviHPb',
    authorizationParams: {
      redirect_uri: 'https://app.staging.qr-pay.net/callback',
      audience: 'https://api-staging.qr-pay.net'
    },
    httpInterceptor: {
      allowedList: [
        {
          uri: 'https://api-staging.qr-pay.net/api/*'
        },
        {
          uri: 'http://qr-pay.test/api/*'
        }
      ]
    }
  },
  currencyCodes: [
    {
      code: 'gbp',
      symbol: '£ (GBP)'
    }
  ],
  supportedCountries: [
    {
      name: 'United Kingdom',
      code: 'GB',
      currency: 'GBP'
    },
    {
      name: 'Ireland',
      code: 'IE',
      currency: 'EUR'
    },
    {
      name: 'USA',
      code: 'US',
      currency: 'USD'
    },
    {
      name: 'Australia',
      code: 'AU',
      currency: 'AUD'
    },
    {
      name: 'Canada',
      code: 'CN',
      currency: 'CAD'
    },
  ]
};

