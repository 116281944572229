import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import {CookieService} from "ngx-cookie-service";
import {ApiService} from "../services/api.service";
import {TenantService} from "../services/tenant.service";
import {WhiteLabelService} from "../services/white-label.service";
import {environment} from "../../environments/environment";
import {UserService} from "../services/user.service";

@Injectable({
  providedIn: 'root'
})
export class TenantGuard implements CanActivate, CanActivateChild {


  constructor(
    private router: Router,
    private cookieService: CookieService,
    private tenant: TenantService,
    private user: UserService,
    private api: ApiService,
    private whiteLabel: WhiteLabelService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise(async (resolve) => {

      const getTenants = await this.getAllTenantsForUser();

      if(!getTenants){
        resolve(false);
      }

      if(!this.tenant.availableTenants.value.length){
        this.router.navigateByUrl('/tenant/create').then(()=>{
          return resolve(false);
        });
      }else{

        // IF IT'S ALREADY BEEN BROUGHT IN FROM COOKIES, ALLOW IT
        if(this.tenant.currentTenantValue){
          return resolve(true);
        }

        // OK NOW CHECK FOR A WHITE LABEL DOMAIN
        if(await this.runCustomDomainCheck(route)){
          await this.handleWhiteLabelling();
          return resolve(true);
        }

        // OK NOW CHECK FOR A QUERY STRING
        if(await this.runQueryStringCheck(route)){
          await this.handleWhiteLabelling();
          return resolve(true);
        }

        // AND FINALLY, CHECK FOR COOKIES
        if(await this.runCookieCheck()){
          await this.handleWhiteLabelling();
          return resolve(true);
        }

        if(this.tenant.availableTenants.value.length > 1){
          this.router.navigateByUrl('/tenant').then(()=>{
            resolve(false);
          });
        }else{
          this.cookieService.set('qrpay_tenant', this.tenant.availableTenants.value[0].tenant_id, {path: '/', sameSite: 'Lax'});
          this.tenant.currentTenantSubject.next(this.tenant.availableTenants.value[0].tenant_id);
          this.user.currentUserRole = this.tenant.availableTenants.value[0];
          resolve(true);
        }

      }
    });

  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise(async (resolve) => {

      if(this.tenant.currentTenantValue){
        resolve(true);
      }

      // OK NOW CHECK FOR A WHITE LABEL DOMAIN
      if(await this.runCustomDomainCheck(route)){
        await this.handleWhiteLabelling();
        resolve(true);
      }

      // OK NOW CHECK FOR A QUERY STRING
      if(await this.runQueryStringCheck(route)){
        await this.handleWhiteLabelling();
        resolve(true);
      }

      // AND FINALLY, CHECK FOR COOKIES
      if(await this.runCookieCheck()){
        await this.handleWhiteLabelling();
        resolve(true);
      }

      this.router.navigateByUrl('/tenant').then(()=>{
        resolve(false);
      });

    });

  }

  handleWhiteLabelling(){

    return new Promise((resolve)=>{
      this.tenant.getPublicBranding().then((WhiteLabelSettings)=>{
        this.whiteLabel.handleDownloadedSettings(WhiteLabelSettings).then(()=>{
          resolve (true);
        });
      }).catch((WhiteLabelError)=>{
        console.log(WhiteLabelError)
        resolve(true);
      });
    })

  }

  runCookieCheck(){

    return new Promise((resolve)=>{
      this.debugLog('Running cookie check');

      if(!this.cookieService.get('qrpay_tenant')) {
        resolve(false);
        this.debugLog('No Cookie Found');
      }else{
        this.tenant.currentTenantValue = this.cookieService.get('qrpay_tenant');
        this.user.currentUserRole = this.tenant.availableTenants.value.find((item) => item.tenant_id === this.tenant.currentTenantValue);
        resolve(true);
      }

    });

  }

  runQueryStringCheck(route: ActivatedRouteSnapshot){

    return new Promise((resolve)=>{
      this.debugLog('Running Query String check');

      if(route.queryParams.tenant) {
        this.cookieService.set('qrpay_tenant', route.queryParams.tenant, {path: '/', sameSite: 'Lax'});
        this.tenant.currentTenantSubject.next(route.queryParams.tenant);
        this.user.currentUserRole = this.tenant.availableTenants.value.find((item) => item.tenant_id === this.tenant.currentTenantValue);
        resolve(true);
      }else{
        this.debugLog('No query string found');
        resolve(false);
      }

    });

  }

  runCustomDomainCheck(route: ActivatedRouteSnapshot){

    return new Promise((resolve)=>{
      this.debugLog('Running custom domain check');
      this.debugLog('No Domain found');
      resolve(false);
      // TODO BUILD THIS CHECK

    });

  }

  debugLog(message:string){
    if(!environment.production){
      console.log(message);
    }
  }

  getAllTenantsForUser() {
    return new Promise((resolve) => {
      this.api.apiGet('user/tenants', false).then((res: any) => {
        this.tenant.availableTenants.next(res);
        resolve(true);
      }).catch((err) => {
        resolve(false);
      })
    });
  }

}
